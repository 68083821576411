import React, { useState } from "react";
import { useTranslation, Link, useI18next } from "gatsby-plugin-react-i18next";
import Trans, { t } from "../../components/Translate";
import { gql, useQuery } from "@apollo/client";
import { graphql } from "gatsby";
import Layout from "../../layouts/default";
import ThemedPager from "../../components/ThemedPager";
import LoadingSpinner from "../../components/LoadingSpinner";
import ScrollTable from "../../components/ScrollTable";
import { enumToLabel } from "../../util";
import "./styles.scss";

const ALL_USERS = gql`
  query AllUsers($first: Int, $skip: Int, $status: UserStatusOption) {
    usersConnection(where: { status: $status }, first: $first, skip: $skip) {
      edges {
        node {
          status
          id
          firstName
          lastName
          emailAddress
          phoneNumber
          qualificationQuickview
          ratePremium
        }
      }
      aggregate {
        count
      }
    }
  }
`;

const Users = ({ location }) => {
  const { navigate } = useI18next();
  const params = new URLSearchParams(location.search);
  const pageNumberParameter = params.get("pageNumber") || 1;
  const [pageNumber, setPageNumber] = useState(Number(pageNumberParameter));
  const recordsPerPage = 30;
  const { data, loading } = useQuery(ALL_USERS, {
    variables: {
      first: recordsPerPage,
      skip: (pageNumber - 1) * recordsPerPage,
    },
    fetchPolicy: "no-cache",
  });

  const users = data?.usersConnection?.edges;

  return (
    <Layout metaTitle={t("USERS_META_TITLE")}>
      <div className="container">
        <h1 className="content-header">
          <Trans i18nKey={"USERS"} />
        </h1>

        <div id="users-page-container" className="position-relative">
          {loading && (
            <div
              style={{ top: "0px" }}
              className="d-flex position-absolute justify-content-center align-items-center mt-1 w-100"
            >
              <LoadingSpinner />
            </div>
          )}
          {/* {users &&
          users.map((user, index) => {
            return (
              <li key={user.node.id}>
                <Link to={`/admin/users/${user.node.id}`}>
                  {user.node.firstName} {user.node.lastName}
                </Link>
              </li>
            );
          })} */}
          {/* <ScrollTable> */}

          {!loading && (
            <>
              {users?.length === 0 ? (
                <div>
                  <p className="mb-2">
                    <b>
                      <Trans i18nKey={"NO_MORE_USERS"} />
                    </b>
                  </p>
                  <a href="/admin/users">
                    <Trans i18nKey={"BACK_TO_FIRST_PAGE"} />
                  </a>
                </div>
              ) : (
                <table className="table _table-striped table-responsive ">
                  <thead className="bg-white">
                    <tr className="text-center">
                      <td
                        style={{ borderBottom: "0" }}
                        className="text-left align-middle font-weight-bold text-nowrap"
                      >
                        <Trans i18nKey={"FIRST_NAME"} />
                      </td>
                      <td className="align-middle font-weight-bold">
                        <Trans i18nKey={"LAST_NAME"} />
                      </td>
                      <td className="align-middle font-weight-bold text-nowrap">
                        <Trans i18nKey={"PHONE_NUMBER"} />
                      </td>
                      <td className="align-middle font-weight-bold">
                        <Trans i18nKey={"EMAIL"} />
                      </td>
                      <td className="align-middle font-weight-bold">
                        <Trans i18nKey={"STATUS"} />
                      </td>
                      <td className="align-middle font-weight-bold">
                        <Trans i18nKey={"QUALIFICATIONS"} />
                      </td>
                      <td className="align-middle font-weight-bold text-nowrap">
                        <Trans i18nKey={"RATE_PREMIUM"} />
                      </td>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {users &&
                      users.map((userNode, index) => {
                        const user = userNode?.node;
                        return (
                          <tr
                            className="table-row"
                            key={user?.id}
                            onClick={() => {
                              navigate(`/admin/users/${user?.id}`);
                            }}
                          >
                            <th
                              style={{ borderBottom: "0" }}
                              className={`align-middle font-weight-normal table-head`}
                            >
                              {user?.firstName}
                            </th>
                            <td className={`text-center align-middle`}>
                              {user?.lastName}
                            </td>
                            <td className={`text-center align-middle`}>
                              {user?.phoneNumber}
                            </td>
                            <td className={`text-center align-middle`}>
                              {user?.emailAddress}
                            </td>
                            <td className={`text-center align-middle`}>
                              <Trans i18nKey={user?.status} />
                            </td>
                            <td className={`text-center align-middle`}>
                              <Trans>{user?.qualificationQuickview}</Trans>
                            </td>
                            <td className={`text-center align-middle`}>
                              {user?.ratePremium}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}

              <div>
                {data?.usersConnection.aggregate.count > recordsPerPage && (
                  <ThemedPager
                    className="mt-0"
                    totalRecords={data?.usersConnection.aggregate.count}
                    dataConnection={data?.usersConnection}
                    recordsPerPage={recordsPerPage}
                    pageNumber={pageNumber}
                    changePageNumber={(pageNumber) => {
                      setPageNumber(pageNumber);
                      navigate(`/admin/users?pageNumber=${pageNumber}`);
                    }}
                  />
                )}
              </div>
            </>
          )}

          {/* </ScrollTable> */}
        </div>
      </div>
    </Layout>
  );
};

export default Users;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
