import React from "react";
import Pagination from "rc-pagination/lib";
// import "rc-pagination/assets/index.css";
import "./styles.scss";

const ThemedPager = ({
  dataConnection,
  pageNumber,
  changePageNumber,
  recordsPerPage,
  totalRecords,
}) => {
  return (
    <div>
      <Pagination
        pageSize={recordsPerPage}
        className="pagination mt-2 mb-2"
        defaultCurrent={pageNumber}
        showTitle={false}
        total={totalRecords}
        itemRender={(current, type, element) => {
          if (type === "next") {
            return (
              <button
                className="btn next-button"
                onClick={() => changePageNumber(pageNumber + 1)}
                disabled={pageNumber * recordsPerPage >= totalRecords}
              >
                Next
              </button>
            );
          }
          if (type === "page") {
            return (
              <button
                className={`btn page-button ${
                  current === pageNumber ? "active" : ""
                }`}
                onClick={() => changePageNumber(current)}
              >
                {current.toString()}
              </button>
            );
          } else if (type === "prev") {
            return (
              <button
                className="btn prev-button"
                onClick={() => changePageNumber(pageNumber - 1)}
                disabled={pageNumber - 1 === 0}
              >
                Prev
              </button>
            );
          }
          return element;
        }}
      />
    </div>
  );
};

export default ThemedPager;
