import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { graphql } from "gatsby";
import Layout from "../../layouts/default";
import { t } from "../../components/Translate";
import UserContainer from "../../pages/profile/components/UserContainer";

const USER = gql`
  query User($id: Int) {
    user(where: { id: $id }) {
      id
      firstName
      lastName
      emailAddress
      # password
      taxIdentificationNumber
      state
      address
      city
      status
      postalCode
      phoneNumber
      generalExperience
      dayOfWeekAvailability
      howDidYouFindUs
      detailCleanExperience
      adminNotes
      userLastRevisionDate
      createdAt
      registrationFormLanguage
      nextUser {
        id
      }
      previousUser {
        id
      }
      qualifiedWorkerRoles {
        type
      }
      preferredWorkDays {
        type
      }
      preferredWorkerRoles {
        type
      }
      preferredWorkSchedule
      languagesSpoken {
        type
        fluency {
          type
        }
      }
      businessName
      preferredWorkEnvironment {
        type
        # abbreviation
      }
    }
  }
`;

const USER_STATUS_OPTIONS = gql`
  {
    __type(name: "UserStatusOption") {
      enumValues {
        name
      }
    }
  }
`;

const User = ({ location }) => {
  var pathArray = location.pathname.split("/");
  const id = pathArray[pathArray.length - 1];

  const { data, loading, refetch } = useQuery(USER, {
    variables: {
      id: Number(id),
    },
    fetchPolicy: "no-cache",
  });
  const { data: userStatusOptionData } = useQuery(USER_STATUS_OPTIONS);

  const statusOptions = userStatusOptionData?.__type?.enumValues;

  const user = data?.user;

  return (
    <Layout metaTitle={t("USER_META_TITLE")}>
      <UserContainer
        statusOptions={statusOptions}
        user={user}
        loading={loading}
        isAdmin={true}
      />
    </Layout>
  );
};

export default User;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
