import React from "react";
import PropTypes from "prop-types";
import UserProvider, { UserConsumer } from "../../contexts/User.js";
import Layout from "../../layouts/default";
import { Link } from "gatsby-plugin-react-i18next";
import Trans, { t } from "../../components/Translate";
import LoadingSpinner from "../../components/LoadingSpinner";
// import UserProvider, { UserConsumer } from "../../contexts/User";

/*
const isLoggedIn = () => {
  const min = Math.ceil(1);
  const max = Math.floor(2);
  const random = Math.floor(Math.random() * (max - min + 1)) + min;
  return true; // random === 1;
};

*/

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const pageMetaTitle = t("NOT_AUTHORIZED_META_TITLE");
  return (
    <>
      <UserProvider>
        <UserConsumer>
          {({ state }) => {
            const me = state?.me;
            const meQueryLoading = state?.meQueryLoading;
            const isAdministrator = me?.isAdministrator === "YES";
            var pathArray = location.pathname.split("/");
            const urlId = pathArray[pathArray.length - 1];

            // const isUserProfilePage = me?.id === Number(urlId);
            // const getUserAuthorization = () => {
            //   if (isAdministrator) {
            //     return true;
            //   }
            //   if (isUserProfilePage) {
            //     return true;
            //   }
            //   return false;
            // };
            // const isUserAuthorized = getUserAuthorization();

            return (
              <>
                {!isAdministrator ? (
                  <Layout metaTitle={pageMetaTitle}>
                    <div className="container mx-auto mt-4 mb-0 mr-0">
                      <div className="row">
                        <div className="col col-12 col-lg-4 m-auto">
                          {meQueryLoading && (
                            <div className="container pt-3">
                              <div className="d-flex justify-content-center align-items-center">
                                <LoadingSpinner />
                              </div>
                            </div>
                          )}
                          {!meQueryLoading && (
                            <>
                              {!me && (
                                <h1 className="content-header">
                                  <Trans i18nKey={"PLEASE_LOG_IN"} />
                                </h1>
                              )}
                              {me && !isAdministrator && (
                                <h1 className="content-header">
                                  <Trans i18nKey={"NOT_AUTHORIZED"} />
                                </h1>
                              )}
                              <span>
                                <Trans i18nKey={"NOT_AUTHORIZED_DESCRIPTION"} />
                              </span>
                              {!me && (
                                <p className="ml-0">
                                  <Trans i18nKey={"LOGIN"} />{" "}
                                  <Link to="/login">
                                    <Trans i18nKey={"HERE"} />
                                  </Link>
                                </p>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Layout>
                ) : (
                  <Component location={location} {...rest} />
                )}
              </>
            );
          }}
        </UserConsumer>
      </UserProvider>
    </>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired
};

export default PrivateRoute;
