import React from "react";
import { Router, Redirect } from "@reach/router";
import {
  Users,
  PrivateRoute,
  User,
  /*EventLog,*/ NotFound,
} from "../../routes";
import Trans, { t } from "../../components/Translate";
import { graphql } from "gatsby";
import Layout from "../../layouts/default";
import { Link } from "gatsby-plugin-react-i18next";
// import NotFound from "../../components/NotFound";

const Index = ({ data, location }) => {
  const language = data?.locales?.edges[0]?.node?.language || null;

  /* since i18n does not add a language code in the URL for english (en), I need support for both a language wildcard
  and no wildcard present. 
  */

  // no optional params: https://github.com/reach/router/blob/e8325ae7a2d7be0a4e1de266ee15929f85fc2d94/website/src/markdown/pages/credits.md#trade-offs-mostly-compared-to-react-router

  const getBasePath = () => {
    if (language === "en") {
      return "/admin";
    }
    return "/:lang/admin";
  };

  if (typeof window !== "undefined") {
    return (
      <Router /*basepath={"/admin"}*/ basepath={getBasePath()}>
        <PrivateRoute path="/users" component={Users}></PrivateRoute>
        <PrivateRoute path="/users/:userId" component={User}></PrivateRoute>
        {/* <PrivateRoute path="/event-log" component={EventLog}></PrivateRoute> */}
        <NotFound default />
      </Router>
    );
  }
  // console.log("location", location?.pathname);

  return null;
};

export default Index;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
