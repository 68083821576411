import React, { useState } from "react";
import Trans, { t } from "../../components/Translate";
import { gql, useQuery } from "@apollo/client";
import { graphql } from "gatsby";
import ThemedPager from "../../components/ThemedPager";
import Layout from "../../layouts/default";
import { useI18next } from "gatsby-plugin-react-i18next";

const EVENT_LOGS = gql`
  query EventLogEntriesConnection {
    eventLogEntriesConnection {
      edges {
        node {
          #   id
          type
          message
          user {
            firstName
            lastName
          }
          data
        }
      }
      #   aggregate {
      #     count
      #   }
    }
  }
`;

const EventLog = ({ location }) => {
  const { navigate } = useI18next();
  const params = new URLSearchParams(location.search);
  const pageNumberParameter = params.get("pageNumber") || 1;
  const [pageNumber, setPageNumber] = useState(Number(pageNumberParameter));
  const recordsPerPage = 30;
  const { data, loading, refetch } = useQuery(EVENT_LOGS, {
    variables: {
      first: recordsPerPage,
      skip: (pageNumber - 1) * recordsPerPage,
    },
  });
  const eventLogs = data?.eventLogEntriesConnection?.edges;
  const eventLogCount = data?.eventLogEntriesConnection?.aggregate?.count;

  console.log("data", data);

  return (
    <Layout metaTitle={t("EVENT_LOG_META_TITLE")}>
      <div className="container">
        <h1 className="content-header">
          <Trans i18nKey={"EVENT_LOG"} />
        </h1>
        <select
          className="form-select mb-2"
          onChange={(e) => {
            console.log("e", e.target.value);
          }}
          aria-label="Default select example"
        >
          <option selected>Select Filter</option>
          <option value="type">Type</option>
          {/* <option value="2">Two</option>
          <option value="3">Three</option> */}
        </select>

        <table className="table _table-striped table-responsive ">
          <thead className="bg-white">
            <tr className="text-center">
              <td className="text-left align-middle font-weight-bold text-nowrap border-bottom-0">
                <Trans i18nKey={"DATE"} />
              </td>
              <td className="text-left align-middle font-weight-bold text-nowrap border-bottom-0">
                <Trans i18nKey={"TYPE"} />
              </td>
              <td className="align-middle font-weight-bold">
                <Trans i18nKey={"MESSAGE"} />
              </td>
              <td className="align-middle font-weight-bold text-nowrap">
                <Trans i18nKey={"USER"} />
              </td>
              <td className="align-middle font-weight-bold">
                <Trans i18nKey={"DATA"} />
              </td>
            </tr>
          </thead>
          <tbody className="bg-white">
            {eventLogs &&
              eventLogs.map((log, index) => {
                const { type, message, user, data } = log?.node;

                // const stringifyData = JSON.stringify(data, null, 1);

                // var obj = JSON.parse(data);
                // console.log("obj", obj);
                return (
                  <tr className="_table-row" key={index}>
                    <td
                      className={`align-middle font-weight-normal table-head border-bottom-0`}
                    >
                      Date
                    </td>
                    <td
                      className={`align-middle font-weight-normal table-head border-bottom-0`}
                    >
                      {type}
                    </td>
                    <td className={`text-center align-middle`}>{message}</td>
                    <td className={`text-center align-middle`}>
                      {user?.firstName} {user?.lastName}
                    </td>
                    <td className={`align-middle`}>
                      {/* <pre className="mb-0">{replaced}</pre> */}
                      <table className="table table-bordered my-0">
                        <tbody>
                          {Object.entries(data).map(([key, value]) => {
                            return (
                              <tr key={key}>
                                <td
                                  className={`text-center align-middle small p-1`}
                                >
                                  {key}
                                </td>
                                <td
                                  className={`text-center align-middle small p-1`}
                                >
                                  {value}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <div>
          {eventLogCount > recordsPerPage && (
            <ThemedPager
              className="mt-0"
              totalRecords={eventLogCount}
              dataConnection={data?.eventLogEntriesConnection}
              recordsPerPage={recordsPerPage}
              pageNumber={pageNumber}
              changePageNumber={(pageNumber) => {
                setPageNumber(pageNumber);
                navigate(`/admin/users?pageNumber=${pageNumber}`);
              }}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default EventLog;
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
